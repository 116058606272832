import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'swiper/dist/css/swiper.css'

import {
  Form,
  FormItem, Input, Radio,
  RadioGroup,
  RadioButton, Button,
  MessageBox,
  Message,
    Loading,
} from 'element-ui';
Vue.use(Form);
Vue.use(FormItem); Vue.use(Input); Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton); Vue.use(Button);
Vue.config.productionTip = false
Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
// 路由拦截，判断是否需要登录
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('isLogin') != 1) {
      next({
        path: '/subscribe'
      })
    } else {
      next()
    }
  }
  else {
    next() // 确保一定要调用 next()
  }
})



// 百度统计
var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?a7754a17844e7f701ac0831b02049606";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
