<template>
  <div class="Wrap" >
    <div class="main ispc">
      <div class="content" id="p_topBar">

        <div class="bg">
          <img :src="require(`@/assets/images/${uiSrc}/pcbg${i}.jpg`)" v-for="i in 5" :key="i" alt="">

        </div>
        <div class="srollcontent linkTitle">
          <div class="logo">
            <img src="@/assets/images/pc/logo.png" alt="">
          </div>
          <div class="subscribeBox">
            <div class="subscribeBoxleft " :class="[{ subscribeBoxleftClose: !subShow }]">
              <img :src="require(`@/assets/images/${uiSrc}/infoBox.png`)" alt="">

              <div class="subscribeContent">
                <div class="intel"> <img src="@/assets/images/pc/intel.png" alt=""></div>
                <div class="time">
                  <img src="@/assets/images/pc/time.png" alt="">
                </div>
                <div class="infocontent">
                  <img :src="require(`@/assets/images/${uiSrc}/infocontent.png`)" alt="">
                </div>
                <div class="subscribeSuccess">
                  <img :src="require(`@/assets/images/${uiSrc}/subscribeSuccess.png`)" alt="">
                </div>
              </div>
              <!-- <div class="clickBox" @click="infoArrowClick"></div>
              <div class="infoArrowClick">
                <img src="@/assets/images/pc/infoArrowBg.png" alt="">
                <div class="arrow" :class="{ arrowClose: !subShow }">
                  <img src="@/assets/images/pc/infoArrow.png" alt="">
                </div>
              </div> -->
            </div>
            <div class="subscribeBoxright" :class="{ subscribeBoxrightActive: !subShow }">
              <!-- https://live.juejin.cn/4354/future2022?mode=player -->
              <!-- 1111//live.byteoc.com/0081/0324 -->
              <iframe v-if="isPc" :src="ishttp + `//live.byteoc.com/0081/0324?platform=pc&mode=player`" frameborder="0"
                allowfullscreen="allowfullscreen" webkitallowfullscreen="true" class="iframe"
                mozallowfullscreen="true"></iframe>
              <iframe v-if="!subShow && isPc" class="commentIframe"
                :src="ishttp + `//live.byteoc.com/0081/0324?platform=pc&mode=menu&menu=comment_imagetext`"
                allowfullscreen="true" frameborder="0"></iframe>
            </div>
          </div>
          <!-- 介绍 -->
          <div class="introduceBox scrollContentItem">
            <div class="title linkTitle">
              <img :src="require(`@/assets/images/${uiSrc}/titleIcon.png`)" alt="">
              <div class="titleText">
                <img :src="require(`@/assets/images/${uiSrc}/title1.png`)" alt="">
              </div>
            </div>
            <div class="introduceContent">
              <img :src="require(`@/assets/images/${uiSrc}/rectangle.png`)" alt="">
              <div class="informationWrap" :class="{ informationWrapBlack: isBlack == 1 }">
                <img :src="require(`@/assets/images/${uiSrc}/introduceInfo.png`)" alt="">
              </div>
              <!-- <div class="introduceText">
                <img src="@/assets/images/pc/introduceText.png" alt="">
              </div> -->
              <!-- <div class="introduceNote">
                <img src="@/assets/images/pc/introduceNote.png" alt="">
              </div> -->
              <!-- <div class="introduceNoteRectangle"></div> -->
            </div>
          </div>
          <!--嘉宾 -->
          <div class="scrollContentItem guestBox">
            <div class="title linkTitle">
              <img :src="require(`@/assets/images/${uiSrc}/titleIcon.png`)" alt="">
              <div class="titleText">
                <img :src="require(`@/assets/images/${uiSrc}/title2.png`)" alt="">
              </div>
            </div>
            <div class="guestContent">
              <div class="guestContentLeft">
                <div class="chapt" :class="{ chaptSelect: i == chaptCount }" v-for="i in 3" :key="i"
                  @click="chaptClick(i, 'guest')">
                  <img :src="require(`@/assets/images/${uiSrc}/guestChapt${i}.png`)" alt="">
                  <div class="guestChaptActive" :class="{ guestChaptActiveShow: i == chaptCount }">
                    <img :src="require(`@/assets/images/${uiSrc}/guestChapt${i}Active.png`)" alt="">
                  </div>

                </div>
              </div>
              <div class="guestContentRight">
                <div class="gusetRectangle">
                  <img :src="require(`@/assets/images/${uiSrc}/gusetRectangle.png`)" alt="">
                  <div class="guest" @scroll="guestScorll('guest', '.pguestItem')" :class="{ guestBlack: isBlack == 1 }"
                    v-if="guestCount">
                    <div class="guestItem pguestItem" @click="guestItemClick(index + 1)"
                      :class="{ selectGuest: index + 1 == guestCount }" v-for="(item, index) in viewGuestArr"
                      :key="index">
                      <img
                        :src="item.type == 1 ? require(`@/assets/images/${uiSrc}/guestType1Bg.png`) : require(`@/assets/images/${uiSrc}/guestBg.png`)"
                        alt="">
                      <div v-if="item.type != 1" class="guestBgBorder">
                        <img :src="require(`@/assets/images/${uiSrc}/guestBgBorder.png`)" alt="">
                      </div>
                      <div v-if="item.type == 1" class="guestBgBorder guestType1BgBorder">
                        <img :src="require(`@/assets/images/${uiSrc}/guestType1BgBorder.png`)" alt="">
                      </div>
                      <div class="people" v-if="item.type != 1">
                        <img :src="item.peopleImg" alt="">
                      </div>
                      <div class="guestInfo" :class="{ guestInfoType1: item.type == 1 }">
                        <div class="meettingName" :title="item.meettingName">{{ item.meettingName }}</div>
                        <div class="line" :class="{ lineBlank: isBlack == 1 }"></div>
                        <template v-if="!item.type">
                          <!-- <div class="meetTime" :class="{ meetTimeBlack: isBlack == 1 }">
                            <img class="infoIcon timeIcon" v-if="isBlack == 1" src="@/assets/images/pcBlack/clock.png"
                              alt="">

                            {{ item.time }}
                          </div> -->
                          <div :title="item.name + ' | ' + item.identity" class="guestName">
                            <img class="nameIcon" v-if="isBlack == 1" src="@/assets/images/pcBlack/peopleIcon.png" alt="">
                            {{ item.name + ' | ' }} <span class="identity">{{
                              item.identity }}</span>
                          </div>
                        </template>
                        <template v-if="item.type == 1">
                          <div class="desc" :title="item.desc">
                            {{ item.desc }}
                          </div>
                          <!-- <div class="typeTime">
                            <img class="infoIcon timeIcon" v-if="isBlack == 1" src="@/assets/images/pcBlack/clock.png"
                              alt="">

                            {{ item.time }}
                          </div> -->
                        </template>
                      </div>
                    </div>

                  </div>
                  <div class="guestMask " :class="{ guestMaskBlack: isBlack == 1 }" v-show="!isBottom">

                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 方法 -->
          <div class="methodBox scrollContentItem">
            <div class="title linkTitle">
              <img :src="require(`@/assets/images/${uiSrc}/titleIcon.png`)" alt="">
              <div class="titleText">
                <img :src="require(`@/assets/images/${uiSrc}/title4.png`)" alt="">
              </div>
            </div>
            <div class="methodContent">
              <!-- <div class="methodContentLeft">
                <img src="@/assets/images/pc/methodBoxBg.png" alt="">
                <div class="itemBox">
                  <div class="item" @click="methodClick(i)" v-for="i in 3 " :key="i"
                    :class="{ seclectMethod: methodCount == i }">
                    <img src="@/assets/images/pc/methodTextBg.png" alt="">
                    <div class="methodText">
                      <img src="@/assets/images/pc/methodText.png" alt="">
                    </div>
                  </div>
                </div>
              </div>
              <div class="methodContentRight">
                <img src="@/assets/images/pc/methodCodeBg.png" alt="">
                <div class="codeTextWrap">
                  <div class="codeTextArrow left"> <img src="@/assets/images/pc/codeTextArrow.png" alt=""></div>
                  <div class="codeArrowText"> <img src="@/assets/images/pc/codeText.png" alt=""></div>
                  <div class="codeTextArrow right"> <img src="@/assets/images/pc/codeTextArrow.png" alt=""></div>
                </div>
                <div class="methodIcon">
                  <img src="@/assets/images/pc/methodIcon.png" alt="">
                </div>
                <div class="code">
                  <img src="@/assets/images/pc/codeBg.png" alt="">
                  <div class="soncode">
                    <img src="@/assets/images/pc/soncode.png" alt="">

                  </div>
                  <div class="codeArrow">
                    <img src="@/assets/images/pc/codeArrow.png" alt="">
                  </div>
                  <div class="codeArrowBottom">
                    <img src="@/assets/images/pc/codeArrow.png" alt="">
                  </div>
                </div>
              </div> -->

              <div class="methodNew">
                <img :src="require(`@/assets/images/${uiSrc}/methodNew.png`)" alt="">
                <div class="whiteBookBtn" v-if="whiteBookBtnShow">
                  <img src="@/assets/images/mobileBlack/whiteBookBtn.png" alt="">
                  <a class="floatBox" target="_blank" href="https://trendinsight.oceanengine.com/arithmetic-report/detail/911"></a>
                </div>
              </div>
              <div class="methodRight">
                <img :src="require(`@/assets/images/${uiSrc}/methodRight.png`)" alt="">
              
                <div class="swiper-container" :style="{ visibility: isBlack == 1 ? '' : 'hidden' }">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" data-swiper-autoplay="5000">
                      <img src="@/assets/images/pcBlack/sonCode.png" alt="">
                    </div>
                    <!-- <div class="swiper-slide" v-if="whiteBookBtnShow" data-swiper-autoplay="5000">
                      <img src="@/assets/images/pcBlack/sonCode2.png" alt="">
                    </div> -->
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- 合作伙伴 -->
          <div class="friendBox scrollContentItem">
            <div class="title ">
              <img :src="require(`@/assets/images/${uiSrc}/titleIcon.png`)" alt="">
              <div class="titleText titleText5">
                <img :src="require(`@/assets/images/${uiSrc}/title5.png`)" alt="">
              </div>
            </div>
            <div class="friendContent">
              <div class="name">
                <img :src="require(`@/assets/images/${uiSrc}/sponsor.png`)" alt="">
              </div>
              <div class="btn">
                <img :src="require(`@/assets/images/${uiSrc}/sponsorBtn.png`)" alt="">
              </div>
              <div class="name name1">
                <img :src="require(`@/assets/images/${uiSrc}/cooperation.png`)" alt="">
              </div>
              <div class="btn btn1">
                <img :src="require(`@/assets/images/${uiSrc}/cooperationBtn.png`)" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tagBox ispc">
      <div class="tagBgActive" :style="{ top: `${(tagCount - 1) * 25}%` }">
        <img src="@/assets/images/pc/tagBgActive.png" alt="">
      </div>

      <div class="tagItem" v-for="i in 4" :key="i" @click="tagClick(i)">
        <div class="tag" :class="[`tag${i}`, { tagActive: tagCount == i }, { tagBlack: isBlack == 1 }]">
          <img v-if="tagCount != i"
            :src="isBlack == 1 ? require(`@/assets/images/pc/tag${i}active.png`) : require(`@/assets/images/pc/tag${i}.png`)"
            alt="">
          <img v-else :src="require(`@/assets/images/pc/tag${i}active.png`)" alt="">
        </div>
      </div>
    </div>
    <div class="topBar ispc" :class="{ topBarBlack: isBlack == 1 }" :style="{ width: `${topBarWidth}px` }">
      <div class="topLogo">
        <img :src="require('@/assets/images/' + uiSrc + '/topLogo.png')" alt="">
      </div>
      <div class="topText">
        <img :src="require('@/assets/images/' + uiSrc + '/topText.png')" alt="">
      </div>
    </div>

    <div class="mobileMain ismobile" :style="{ overflow: mbTag != 1 ? 'hidden' : '' }">
      <div class="mobileTopBar" :class="{ mobileTopBarBlack: isBlack == 1 }">
        <div class="logo">
          <img :src="require(`@/assets/images/${muiSrc}/logo.png`)" alt="">
        </div>
      </div>
      <iframe v-if="!isPc" :src="ishttp + `//live.byteoc.com/0081/0324?platform=mobile&mode=player`" frameborder="0"
        allowfullscreen="allowfullscreen" webkitallowfullscreen="true" class="iframe" mozallowfullscreen="true"></iframe>
      <div class="mbcontent" :class="[{ 'mbcontenttagother': mbTag != 1 }, { 'mbcontentBlack': isBlack == 1 }]">
        <div class="initroduceTagBox">
          <div class="initroduceTag" v-for="i in 3" :key="i" @click="introduceClick(i)">
            <img :style="{ visibility: i == mbTag ? 'hidden' : 'visible' }"
              :src="require(`@/assets/images/${muiSrc}/mbtagBg.png`)" alt="">
            <div class="selectBg" v-show="i == mbTag">
              <img :src="require(`@/assets/images/${muiSrc}/mbtagBgActive.png`)" alt="">
            </div>
            <div class="tagtitleText">
              <img :src="require(`@/assets/images/mobile/tagtitle${i}.png`)" alt="">
            </div>
          </div>
        </div>
        <!-- 介绍 -->
        <div class="itemCountent introduceiContent" v-show="mbTag == 1">
          <div class="titleBox">
            <img :src="require(`@/assets/images/${muiSrc}/mbtitleBg.png`)" alt="">
            <div class="titleText">
              <img :src="require(`@/assets/images/${muiSrc}/titleText1.png`)" alt="">
            </div>
          </div>
          <div class="introduceText">
            <img :src="require(`@/assets/images/${muiSrc}/introduceText.png`)" alt="">
          </div>
          <div class="introduceNote">
            <img src="@/assets/images/mobile/introduceNote.png" alt="">
          </div>
          <div class="line"></div>
          <div class="iconBox">
            <div class="introduceicon" v-for="i in 4" :key="i">
              <img :src="require(`@/assets/images/${muiSrc}/introduceicon${i}.png`)" alt="">
            </div>
          </div>
        </div>
        <iframe class="miframe " v-if="!isPc" :class="{ mobileComment: mbTag == 2 }"
          :src="ishttp + `//live.byteoc.com/0081/0324?platform=mobile&mode=comment&background=transparent`"
          allowfullscreen="true" frameborder="0"></iframe>
        <iframe class="miframe " v-if="!isPc" :class="{ mobileImagetext: mbTag == 3 }"
          :src="ishttp + `//live.byteoc.com/0081/0324?platform=mobile&mode=imagetext&background=transparent`"
          allowfullscreen="true" frameborder="0"></iframe>
        <!-- 嘉宾 -->
        <div v-show="mbTag == 1" class="itemCountent mbguestContent">
          <div class="titleBox">
            <img :src="require(`@/assets/images/${muiSrc}/mbtitleBg.png`)" alt="">
            <div class="titleText">
              <img :src="require(`@/assets/images/${muiSrc}/titleText2.png`)" alt="">
            </div>
          </div>
          <div class="guestBox">
            <img :src="require(`@/assets/images/${muiSrc}/guestBoxBg.png`)" alt="">



            <div class="mbguest" v-if="guestCount" @scroll="guestScorll('mbguest', '.mguestItem')">
              <div class="guestItem mguestItem" :style="{ 'margin': item.type == 1 ? '-3% -3% -3% 0' : '' }"
                :class="[{ selectGuest: index + 1 == guestCount }, { guestItemBlack: isBlack == 1 }]"
                @click="guestItemClick(index + 1)" v-for="(item, index) in viewGuestArr" :key="index">
                <img
                  :src="item.type == 1 ? require(`@/assets/images/${muiSrc}/guestType1Bg.png`) : require(`@/assets/images/${muiSrc}/guestBg.png`)"
                  alt="">
                <div v-if="item.type != 1 && index + 1 == guestCount" class="guestBgBorder">
                  <img :src="require(`@/assets/images/${muiSrc}/guestBgBorder.png`)" alt="">
                </div>
                <div v-if="item.type == 1 && index + 1 == guestCount" class="guestBgBorder guestType1BgBorder">
                  <img :src="require(`@/assets/images/${muiSrc}/guestType1BgBorder.png`)" alt="">

                </div>
                <div class="people" v-if="item.type != 1">
                  <img :src="item.peopleImg" alt="">
                </div>
                <div class="guestInfo" :class="{ guestInfoType1: item.type == 1 }">
                  <div class="meettingName" :title="item.meettingName">{{ item.meettingName }}</div>
                  <div class="line" :class="{ lineBlank: isBlack == 1 }"></div>
                  <template v-if="!item.type">
                    <!-- <div class="meetTime" :class="{ meetTimeBlack: isBlack == 1 }">
                      <img class="infoIcon timeIcon" v-if="isBlack == 1" src="@/assets/images/pcBlack/clock.png" alt="">
                      {{ item.time }}
                    </div> -->
                    <div :title="item.name + ' | ' + item.identity" class="guestName">
                      <img class="nameIcon" v-if="isBlack == 1" src="@/assets/images/pcBlack/peopleIcon.png" alt="">
                      {{ item.name + ' | ' }} <span class="identity">{{
                        item.identity }}</span>
                    </div>
                  </template>
                  <template v-if="item.type == 1">
                    <div class="desc" :title="item.desc">
                      {{ item.desc }}
                    </div>
                    <!-- <div class="typeTime">
                      <img class="infoIcon timeIcon" v-if="isBlack == 1" src="@/assets/images/pcBlack/clock.png" alt="">
                      {{ item.time }}
                    </div> -->
                  </template>
                </div>
              </div>

            </div>

            <div class="guestMask" :class="{ guestMaskBlack: isBlack == 1 }" v-show="!isBottom">

            </div>

            <div class="guestTitleBox">
              <img :src="require(`@/assets/images/${muiSrc}/guestTitleBg.png`)" alt="">
              <div class="checktagBg" :class="{ checktagBg2: chaptCount == 2, checktagBg3: chaptCount == 3 }">
                <img src="@/assets/images/mobile/checktagBg.png" alt="">
              </div>
              <div class="guestTitle" :class="`guestTitle${i}`" @click="chaptClick(i, 'mbguest')" v-for="i in 3" :key="i">
                <img :src="require(`@/assets/images/mobile/guestTitle${i}.png`)" alt="">
              </div>
            </div>
          </div>
        </div>
        <!-- 方法 -->
        <div v-show="mbTag == 1" class="itemCountent mbmethodContent">
          <div class="titleBox">
            <img :src="require(`@/assets/images/${muiSrc}/mbtitleBg.png`)" alt="">
            <div class="titleText">
              <img :src="require(`@/assets/images/${muiSrc}/titleText3.png`)" alt="">
            </div>
          </div>
          <div class="mbmethod">
            <img :src="require(`@/assets/images/${muiSrc}/mbmethod.png`)" alt="">
            <div class="whiteBookBtn" v-if="whiteBookBtnShow">
              <img src="@/assets/images/mobileBlack/whiteBookBtn.png" alt="">
              <a class="floatBox" target="_blank" href="https://trendinsight.oceanengine.com/arithmetic-report/detail/911"></a>
            </div>
            <div class="methodIcon">
              <img src="@/assets/images/mobile/methodIcon.png" alt="">

            </div>
          </div>
          <div class="methodsBottom">
            <img :src="require(`@/assets/images/${muiSrc}/methodsBottom.png`)" alt="">

          </div>

        </div>
        <!-- 合作 -->
        <div v-show="mbTag == 1" class="itemCountent mbfriendContent">
          <div class="titleBox">
            <img :src="require(`@/assets/images/${muiSrc}/mbtitleBg.png`)" alt="">
            <div class="titleText titleText4">
              <img :src="require(`@/assets/images/${muiSrc}/titleText4.png`)" alt="">
            </div>
          </div>
          <div class="friendBox">
            <div class="friend">
              <img :src="require(`@/assets/images/${muiSrc}/friend1.png`)" alt="">

            </div>
            <div class="friend">
              <img :src="require(`@/assets/images/${muiSrc}/friend2.png`)" alt="">

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { eventClick, isPC } from '@/util/util'
// @ is an alias to /src
import Swiper from 'swiper';
export default {
  components: {
  },
  data() {
    return {
      uiSrc: '',
      muiSrc: '', //怕出问题再加一个
      isBlack: '', //1黑色版本
      whiteBookBtnShow: false,// 是否展示白皮书
      ishttp: location.protocol,
      subShow: true,  //展示评论区的
      tagCount: 1,  //当前选中的tag
      methodCount: 1, //当前选中的方法
      guestCount: 1,  //选中的嘉宾
      chaptCount: 1,
      guestArr: [
        [
          // { type: 1, name: '潘蓉（浙江卫视）/刘源（四川卫视）', identity: '主持人', meettingName: '抖音生活服务助力美好行业宣导片', peopleImg: null, time: '14:00-14:10', desc: '抖音生活服务助力美好行业宣导片' },
          { type: 1, name: '朱时雨', identity: '抖音生活服务业务负责人', meettingName: '启幕致辞 好局开场', peopleImg: null, time: '14:10-14:15', desc: '抖音生活服务的开局新展望' },
          { type: 1, name: '副厅长', identity: '四川省商务厅', meettingName: '砥砺前行 同频共振', peopleImg: null, time: '14:15-14:20', desc: '领导致辞' },
          { type: 1, name: '李炜', identity: '抖音生活服务业务副总裁', meettingName: '长效发展，砥砺前行', peopleImg: null, time: '14:20-14:35', desc: '综合行业经营伙伴计划启动仪式' },
        ],
        [
          { name: '陈琦', identity: '抖音生活服务综合业务中心负责人', meettingName: '用心生活，共创美好', peopleImg: require(`@/assets/images/pc/guestImg/5.png`), time: '14:35-14:55' },
          { name: '林文斌', identity: '巨量引擎商业分析负责人 巨量引擎城市研究院院长', meettingName: '数观其变，论综合行业经营破局之道', peopleImg: require(`@/assets/images/pc/guestImg/6.png`), time: '14:55-15:10' },
          { name: '陈阳', identity: '抖音生活服务综合行业休闲娱乐业务负责人', meettingName: '休娱购物新机遇，平台共建新生态', peopleImg: require(`@/assets/images/pc/guestImg/12.png`), time: '16:15-16:30' },
          { name: '刘晓峰', identity: '抖音生活服务综合行业丽人业务负责人', meettingName: '发现美，感受美，创造美', peopleImg: require(`@/assets/images/pc/guestImg/13.png`), time: '16:30-16:45' },
          { name: '李翔', identity: '抖音生活服务综合行业Life Event业务负责人', meettingName: '激发兴趣交易，助力消费升级', peopleImg: require(`@/assets/images/pc/guestImg/14.png`), time: '16:45-17:00' },
          { name: '韩超', identity: '抖音生活服务综合行业服务商管理业务负责人', meettingName: '维势同行，聚力生态伙伴，合作创造价值', peopleImg: require(`@/assets/images/pc/guestImg/10.png`), time: '15:45-16:00' },
          { name: '蔡红蕾', identity: '抖音生活服务综合行业产品负责人', meettingName: '洞幽察微，助力综合商家的制胜密码', peopleImg: require(`@/assets/images/pc/guestImg/7.png`), time: '15:10-15:25' },
          { name: '邓卓', identity: '抖音生活服务综合达人运营负责人', meettingName: '高效赋能，让好内容与好生意相互成就', peopleImg: require(`@/assets/images/pc/guestImg/11.png`), time: '16:00-16:15' },
          { name: '骆怡航', identity: '火山引擎AI解决方案负责人', meettingName: '智能创作，商家内容生产新解法', peopleImg: require(`@/assets/images/pc/guestImg/8.png`), time: '15:25-15:35' },
          { name: '刘结', identity: '英特尔行业销售总监', meettingName: '数智引领，线下经营“芯”方向', peopleImg: require(`@/assets/images/pc/guestImg/9.png`), time: '15:35-15:45' },

        ], [
          { name: '罗晴', identity: '天虹购百数字化产品运营总经理', meettingName: '在抖音生活服务构建的生意新增长', peopleImg: require(`@/assets/images/pc/guestImg/17.png`), time: '17:00-17:10' },
          { name: '樊文花', identity: '樊文花面部护理连锁创始人兼董事长', meettingName: '“樊花”，以美共振抖音生活服务数字化经营之道', peopleImg: require(`@/assets/images/pc/guestImg/15.png`), time: '17:10-17:20' },
          { name: '张涛', identity: '银吉姆首席战略官', meettingName: '颠覆传统，在抖音生活服务经营的革新之路', peopleImg: require(`@/assets/images/pc/guestImg/16.png`), time: '17:20-17:30' },
          { name: '许嘉骏', identity: '上海青潮区信息科技有限公司 联合创始人', meettingName: '共创共赢，品牌与服务商的“双向奔赴”', peopleImg: require(`@/assets/images/pc/guestImg/18.png`), time: '17:30-17:40', },

        ]],
      viewGuestArr: [],
      showTimer: null,
      btnShowTimer: null,

      mbTag: 1,  // 手机选中的标签
      topBarWidth: '',
      isBottom: false, //判断嘉宾是否滚到低了
      isPc: '',
    }
  },
  methods: {

    tagClick(val) {
      //   获取所有锚点元素
      const contents = document.querySelectorAll('.linkTitle')
      const contentsOffsetTop = []
      contents.forEach(item => {
        contentsOffsetTop.push(item.offsetTop)
      })
      const main = document.getElementsByClassName('main')[0]

      main.scrollTo({
        top: contentsOffsetTop[val - 1],
        behavior: "smooth"
      })
      setTimeout(() => {
        this.tagCount = val

      }, 200);
      eventClick(`导航标签,标签${val}`)
    },
    // 嘉宾点击
    guestItemClick(val) {
      this.guestCount = val
      if (val == this.viewGuestArr.length) {
        this.isBottom = true;
      } else {
        this.isBottom = false;

      }
      eventClick(`嘉宾点击${this.viewGuestArr[val - 1].name}`)
    },
    chaptClick(val, className) {
      if (this.chaptCount == val) return
      this.chaptCount = val
      this.viewGuestArr = this.guestArr[val - 1]

      let guest = document.getElementsByClassName(className)[0]
      console.log(guest)
      guest.scrollTo({
        top: 0,
        behavior: "smooth"
      })
      eventClick(`嘉宾篇章点击,篇章${val}`)
    },
    scorllAnchor() {
      //  获取当前页面滚到哪了
      const main = document.getElementsByClassName('main')[0] || ''
      const scrollTop = main.scrollTop
      const screenHeight = window.screen.height
      // // 获取iframe属性
      // const subscribeBoxright = document.querySelector('.subscribeBoxright')
      // const tagBox = document.querySelector('.tagBox')
      // // 判断是否显示导航
      // if (scrollTop < subscribeBoxright.offsetTop + subscribeBoxright.offsetHeight / 2) {
      //   tagBox.style.display = 'none'
      // } else {
      //   tagBox.style.display = 'block'
      // }
      //   获取所有锚点元素
      const contents = document.querySelectorAll('.linkTitle')
      //  获取锚点元素的offsetTop值，并收集在一个数组
      const contentsOffsetTop = []
      contents.forEach(item => {
        contentsOffsetTop.push(item.offsetTop)
      })
      //  遍历锚点元素的offsetTop值
      for (let i = 0; i < contentsOffsetTop.length; i++) {
        if (scrollTop >= contentsOffsetTop[i] - screenHeight / 4) {
          this.tagCount = i + 1
        }
      }
    },
    methodClick(val) {
      console.log('11')
      if (this.methodCount == val) return
      this.methodCount = val
    },
    guestScorll(p_calss, className) {
      // 获取当前滚到哪了
      let guest = document.getElementsByClassName(p_calss)[0]
      let scrollTop = guest.scrollTop
      // 获取匡高
      let boxHeight = guest.clientHeight
      let scrollHeight = guest.scrollHeight
      // 获取所有嘉宾元素
      const guestItem = document.querySelectorAll(className)

      //拿每一个的offsetTop值 
      let itemOffsetTop = []

      guestItem.forEach(item => {
        itemOffsetTop.push(item.offsetTop)
      })
      if (scrollTop == 0) {
        this.guestCount = 1;
        this.isBottom = false;

      } else if (
        boxHeight + scrollTop >= scrollHeight
      ) {
        this.guestCount = guestItem.length
        this.isBottom = true;
      }
      else {
        this.isBottom = false;

        for (let i = 0; i < itemOffsetTop.length; i++) {
          if (scrollTop >= itemOffsetTop[i] - boxHeight / 2) {
            this.guestCount = i + 1
          }
        }
      }

    },

    // mb
    introduceClick(val) {
      if (this.mbTag == val) return
      this.mbTag = val
      eventClick(`手机端介绍标签${val}点击`)
    },

  }, mounted() {
    // 监听滚动事件
    window.addEventListener('scroll', this.scorllAnchor, true)
    // 默认展示第一组嘉宾
    this.viewGuestArr = this.guestArr[0]

    // 判断是否开始直播了

    this.showTimer = setInterval(() => {
      let now = new Date();
      let time1 = "2023/3/24 14:02";
      if (now.getTime() >= new Date(time1).getTime()) {
        console.log('1')
        // this.subShow = false;
        this.isBlack = 1
        this.uiSrc = 'pcBlack'
        this.muiSrc = 'mobileBlack'
        clearInterval(this.showTimer)

      } else {
        this.isBlack = 0
        this.uiSrc = 'pc'
        this.muiSrc = 'mobile'
      }
    }, 1000)


    // whiteBookBtnShow
    // 判断是否出白皮书
    this.btnShowTimer = setInterval(() => {
      let now = new Date();
      let time2 = "2023/3/24 15:00";
      // console.log('123')
      if (now.getTime() >= new Date(time2).getTime()) {
        console.log(321)
        this.whiteBookBtnShow = true;
        clearInterval(this.btnShowTimer)
      }
    }, 1000);

    this.isPc = isPC()
    setTimeout(() => {
      if (this.isPc) {
      new Swiper('.swiper-container', {
        loop: true,
        speed: 1000,
        // autoplay: {
        //   disableOnInteraction: false,
        // },
        autoplay:false
      })

    }
    }, 1000);
  
    window.onresize = () => {
      return (() => {
        this.$nextTick(() => {
          var child = document.querySelector(".Wrap") || '';
          var pTopBar = document.querySelector('#p_topBar')
          var scrollbarWidth = child.parentNode.offsetWidth - pTopBar.offsetWidth;
          this.topBarWidth = (child.parentNode.offsetWidth - scrollbarWidth)
          console.log(child.parentNode.offsetWidth, pTopBar.offsetWidth)
        })
      })()
    }
    setTimeout(() => {
      this.$nextTick(window.onresize())
    }, 1000);

  },
  updated(){
    this.$nextTick(window.onresize())

  },
  destroyed() {
    window.removeEventListener('scroll', this.scorllAnchor, true)
  },
}


</script>
<style lang="less" scoped>
@import '@/assets/css/base.less';
@import '@/assets/css/animate.less';

.infoIcon {
  .p_attr_val(width, 24);
  .position50();
  left: -30%;
}

.Wrap {
  .tagBox {
    position: fixed;
    .p_attr_val(top, 480);

    transform: translate(0, -50%);
    .p_attr_val(left, 54);
    z-index: 999;

    .tagBgActive {
      .p_attr_val(width, 150);
      position: absolute;
      left: -2%;
      top: 0%;
      transition: 0.3s ease all;
    }

    .tagItem {
      .p_attr_val(margin-bottom, 15);
      .p_attr_val(height, 44);
      cursor: pointer;

      img {
        margin: 0 auto;
      }

      .tag {
        .p_attr_val(width, 105);
        .p_attr_val(font-size, 20);
        position: absolute;
        top: 50%;
        left: 70%;
        transform: translate(-50%, -50%);
        text-align: center;
        position: relative;
        white-space: nowrap;

        &::before {
          display: block;
          content: '';
          .p_attr_val(width, 11);
          .p_attr_val(height, 44);
          background: url('../assets/images/pc/tagBg.png') 100% no-repeat;
          position: absolute;
          z-index: -1;
          top: -39%;
          left: -23%;
          transition: 0.3s ease all;
        }

        &Black::before {
          background: url('../assets/images/pcBlack/tagBg.png') 100% no-repeat;
        }
      }

      .tagActive {
        color: #fff;

        &::before {
          opacity: 0;
        }
      }
    }
  }
}

.main {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .content {
    position: relative;
    width: 100%;

    .bg {
      width: 100%;
      position: relative;
      font-size: 0;


    }

    .srollcontent {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      .logo {
        .p_attr_val(margin-top, 52);
        .p_attr_val(margin-left, 54);
        .p_attr_val(width, 244);
        visibility: hidden;
      }

      .subscribeBox {
        width: 100%;
        .p_attr_val(margin-top, 50);
        position: relative;

        .subscribeBoxleft {
          .p_attr_val(width, 618);
          position: relative;
          .p_attr_val(margin-right, 40);
          transition: ease all 0.3s;
          z-index: 1;

          .subscribeContent {
            position: absolute;
            .p_attr_val(height, 569);
            .p_attr_val(bottom, 10);
            .p_attr_val(right, 80);

            .intel {
              .p_attr_val(width, 242);

            }

            .time {
              .p_attr_val(margin-top, 22);
              .p_attr_val(margin-bottom, 40);
              .p_attr_val(width, 184);
            }

            .infocontent {
              .p_attr_val(width, 243);

            }

            .subscribeSuccess {
              .p_attr_val(width, 266);
              .p_attr_val(margin-top, 72);
            }


          }

          .infoArrowClick {
            .p_attr_val(width, 62);
            position: absolute;
            top: 46%;
            right: -2.5%;
            cursor: pointer;
            z-index: -1;

            .arrow {
              position: absolute;
              top: 32%;
              left: 10%;
              .p_attr_val(width, 49);
              animation: leftRun 800ms ease infinite alternate-reverse;
            }

            .arrowClose {
              transform: rotate(180deg);
              top: 24%;
              animation: rightRun 800ms ease infinite alternate-reverse;
              left: 3%;
            }
          }

          .clickBox {
            .p_attr_val(width, 62);
            .p_attr_val(height, 62);
            position: absolute;
            top: 46%;
            right: -2.5%;
            cursor: pointer;
          }
        }



        .subscribeBoxright {
          position: absolute;
          left: 33.5%;
          top: 3.8%;


          display: flex;
          align-items: center;
          transition: ease all 0.3s;

          .iframe {
            .p_attr_val(width, 580/9*16);
            .p_attr_val(height, 580);
            transition: ease all 0.3s;
          }

          .commentIframe {
            .p_attr_val(width, 320);
            .p_attr_val(height, 580);
            .p_attr_val(margin-left, 10);
            transition: ease all 0.3s;

          }
        }

        .subscribeBoxrightActive {
          // left: 21%;
          .position50();
          left: 55.1%;

          .iframe {
            .p_attr_val(width, 700/9*16);
            .p_attr_val(height, 700);
            transition: ease all 0.3s;
          }

          .commentIframe {
            .p_attr_val(width, 350);
            .p_attr_val(height, 700);
            .p_attr_val(margin-left, 15);

          }
        }
      }

      .subscribeBoxleftClose {
        .p_attr_val(margin-left, -540);
        visibility: hidden;
      }


      .scrollContentItem {
        .p_attr_val(margin-top, 100);
        .p_attr_val(margin-left, 243);

        .title {
          .p_attr_val(width, 577);
          .p_attr_val(margin-bottom, -25);

          position: relative;

          &>img {
            animation: toBottomRun 1000ms ease infinite alternate;

          }

          .titleText {
            .p_attr_val(width, 162);
            position: absolute;
            .p_attr_val(top, 12);
            .p_attr_val(left, 100);

          }

          .titleText5 {
            .p_attr_val(width, 192);
          }
        }
      }

      .introduceBox {

        .introduceContent {
          .p_attr_val(width, 1422);
          position: relative;
          margin-left: 2.710417vw;

          .informationWrap {
            position: absolute;
            .p_attr_val(left, -66);
            .p_attr_val(top, 85);
            .p_attr_val(width, 436);

            &Black {
              .p_attr_val(top, 105);

            }
          }

          // .introduceText {
          //   width: 50.854167vw;
          //   position: absolute;
          //   .p_attr_val(top, 100);
          //   .p_attr_val(left, 420);

          // }

          // .introduceNote {
          //   position: absolute;
          //   .p_attr_val(bottom, 100);
          //   .p_attr_val(left, 420);
          //   .p_attr_val(width, 362);

          // }

          // .introduceNoteRectangle {
          //   .p_attr_val(width, 56);
          //   .p_attr_val(height, 3);
          //   background-color: #ff7e0a;
          //   position: absolute;
          //   .p_attr_val(bottom, 100);
          //   right: 6.333333vw;

          //   animation: introduceNoteRectangleRun 1000ms ease-in infinite alternate;
          // }
        }

      }

      .processBox {
        .processContent {
          display: flex;
          align-content: center;

          .processItem {
            position: relative;

            .chaptTextBg {
              .p_attr_val(width, 465);
            }

            .chaptTextBg2 {
              .p_attr_val(width, 620);

            }

            .chaptNumber {
              position: absolute;
              bottom: 14%;
              right: 13%;
            }

            .chaptNumber1 {
              .p_attr_val(width, 139);

            }

            .chaptNumber2 {
              .p_attr_val(width, 162);
              right: 7%;
            }

            .chaptNumber3 {

              .p_attr_val(width, 160);
            }

            .chaptTextAction {
              height: 0%;
            }

            .chaptTextAction1 {
              width: 87%;
              position: absolute;
              top: 12%;
              left: 6.1%;
              background-color: #ff5101;
              border-bottom-left-radius: 8%;
              border-bottom-right-radius: 8%;
            }

            .chaptTextAction2 {
              width: 90.9%;
              transform-origin: 50% 100%;
              position: absolute;
              top: 12%;
              left: 4.5%;
              background-color: #ff5101;
              border-bottom-left-radius: 7%;
              border-bottom-right-radius: 7%;
            }

            .chaptTextAction3 {
              width: 87.8%;
              transform-origin: 50% 100%;
              position: absolute;
              top: 12%;
              left: 6.1%;
              background-color: #ff5101;
              border-bottom-left-radius: 8%;
              border-bottom-right-radius: 8%;
            }

            .chaptText {
              .p_attr_val(width, 270);
              position: absolute;
              top: 23%;
              left: 17%;
            }

            .processActive {
              .p_attr_val(width, 492);
              .position50();
              opacity: 0;
            }

            .processActive2 {
              .p_attr_val(width, 654);

            }

            &:hover {
              .chaptTextAction {
                height: 75%;
                transition: all ease 0.5s;
              }

              &>img,
              .chaptTextAction,
              .chaptNumber,
              .chaptText {
                animation: opacity1to0 500ms linear both;
              }

              .processActive {
                animation: opacity0to1 500ms linear both;

              }
            }
          }

        }
      }

      .guestBox {
        .guestContent {
          display: flex;
          .p_attr_val(width, 1526);

          .guestContentLeft {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .p_attr_val(margin-left, -15);
            transform: translateY(-0.5%);

            :nth-child(2) {
              margin: -2% 0;
            }

            .chapt {
              position: relative;
              .p_attr_val(width, 564);
              cursor: pointer;

              // &::after {
              //   content: '';
              //   display: block;
              //   position: absolute;
              //   width: 0%;
              //   height: 73.6%;
              //   top: 9.7%;
              //   right: 4.6%;
              //   // background-color: #ff773a;
              //   // background-image: linear-gradient(#ff783b, #ff5c11, #ff783b);
              //   .p_attr_val(border-top-right-radius, 20);
              //   .p_attr_val(border-bottom-right-radius, 20);
              //   transform-origin: 50% 100%;
              //   transition: ease all 0.4s;
              // }
              // &:hover::after {
              //   width: 87%;
              // }
              .guestChaptActive {
                position: absolute;
                .p_attr_val(width, 576);
                left: 0;
                top: 5%;
                opacity: 0;
                transition: ease all 0.3s;
              }

              .guestChaptActiveShow {
                opacity: 1;


              }

              &:hover {
                .guestChaptActive {
                  opacity: 1;

                }
              }

            }


          }

          .guestContentRight {
            // font-family: 'zt';
            .p_attr_val(margin-left, -30);

            .gusetRectangle {
              .p_attr_val(width, 959);
              position: relative;

              .guest {
                position: absolute;
                width: 90%;
                height: 85%;
                top: 6%;
                left: 5%;
                padding-top: 2%;
                padding-bottom: 6%;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                overflow: hidden;
                overflow-y: auto;

                &Black {

                  color: #fff;

                  .meettingName,
                  .guestName {
                    color: #fff !important;

                  }
                }

                &::-webkit-scrollbar {
                  .p_attr_val(width, 7);
                }

                &::-webkit-scrollbar-track {
                  border-radius: 5px;
                  background: #ffca9b;
                }

                /* 滚动条滑块 */
                &::-webkit-scrollbar-thumb {
                  border-radius: 5px;
                  background-image: linear-gradient(#fa8e54, #f77234, #ffca9b);
                  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
                }

                ::-webkit-scrollbar-thumb:window-inactive {
                  background: rgba(255, 0, 0, 0.4);
                }

                .guestItem {
                  position: relative;
                  .p_attr_val(width, 771);
                  margin-bottom: -2%;
                  transition: all ease 0.3s;

                  .people {
                    width: 11.729167vw;
                    position: absolute;
                    bottom: 20%;
                    left: 10%;
                    transition: ease all 0.3s;
                  }

                  .guestInfo {
                    .p_attr_val(width, 300);
                    position: absolute;
                    left: 41%;
                    top: 46%;
                    transform: translate(0%, -50%);

                    .meettingName {
                      color: #2e1d15;
                      width: 100%;
                      .p_attr_val(font-size, 22);
                      overflow: hidden;
                      text-overflow: ellipsis;
                      font-weight: 600;
                      letter-spacing: 3px;
                    }

                    .line {
                      .p_attr_val(width, 47);
                      .p_attr_val(height, 2);
                      background-color: #ff7e0a;
                      margin: 10px 0;
                    }

                    .lineBlank {
                      background-color: #fff;

                    }

                    .meetTime {
                      .p_attr_val(font-size, 18);
                      font-weight: 600;
                      .p_attr_val(margin-bottom, 15);
                      // font-family: 'number';

                      position: relative;
                    }

                    .meetTimeBlack {
                      text-indent: 10%;

                      .timeIcon {
                        left: 3.5%;
                      }
                    }

                    .guestName {
                      width: 100%;
                      // overflow: hidden;
                      // text-overflow: ellipsis;
                      color: #2e1d15;
                      position: relative;
                      .p_attr_val(font-size, 18);

                      .identity {
                        .p_attr_val(font-size, 12);

                      }

                      .nameIcon {
                        .p_attr_val(width, 20);
                        // .position50();
                        // left: 3.5%;    transform: translateY(18%);
                        display: inline-block;
                        transform: translateY(18%);
                        margin-right: 2%;
                      }
                    }

                    .desc {
                      width: 100%;
                      .p_attr_val(font-size, 18);
                      font-weight: 500;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }

                    .typeTime {
                      position: absolute;
                      top: 6%;
                      .p_attr_val(font-size, 18);
                      right: -3%;
                      font-weight: 600;
                      // font-family: 'number';

                    }

                  }

                  .guestInfoType1 {
                    width: 30vw;
                    position: absolute;
                    top: 28%;
                    left: 12%;
                    white-space: nowrap;
                    transform: translate(0%, 0%);
                  }

                  .guestBgBorder {
                    .p_attr_val(width, 777);
                    position: absolute;
                    top: 0;
                    left: -0.3%;
                    display: none;
                  }

                  .guestType1BgBorder {
                    width: 100%;
                    position: absolute;
                    top: 0%;
                    left: 0%;
                    transform: scale(1);
                    display: none;
                  }

                  &:hover {
                    .guestBgBorder {
                      display: block;
                    }

                    .people {
                      bottom: 19.5%;
                    }
                  }
                }

                .selectGuest {
                  transform: scale(1.12);
                  transform-origin: 100% 50%;

                  .lineBlank {
                    background-color: #ff7e0a !important;
                  }
                }
              }

              .guestMask {
                position: absolute;
                width: 89.5%;
                height: 20%;
                bottom: 9%;
                right: 5.78%;
                background-image: linear-gradient(to top, #fef1e2, rgba(255, 255, 255, 0));
                filter: blur(20px);
                pointer-events: none;

                &Black {
                  background-image: linear-gradient(to top, #36323e, rgba(255, 255, 255, 0));
                }
              }
            }
          }
        }
      }

      .methodBox {

        .methodContent {
          position: relative;
          display: flex;
          align-items: center;
          .p_attr_val(margin-top, -230);

          .methodNew {
            .p_attr_val(width, 1007);
            position: relative;
            .p_attr_val(margin-left, -20);

            .whiteBookBtn {
              position: absolute;
              .p_attr_val(width, 211);
              right: 27.6%;
              bottom: 30%;
            }
          }

          .methodRight {
            .p_attr_val(width, 467);
            .p_attr_val(margin-top, 73);
            position: relative;

            .swiper-container {
              .position50();
              width: 70%;
              transform: translate(-50%, -54%);
              overflow: hidden;
            }
          }
        }
      }

      .friendBox {
        .p_attr_val(margin-top, -60);

        .friendContent {
          display: flex;
          align-items: center;
          .p_attr_val(margin-top, 13);
          .p_attr_val(margin-left, 17);

          .name {
            .p_attr_val(width, 110);

          }

          .name1 {
            .p_attr_val(width, 150);
          }

          .btn {
            .p_attr_val(width, 277);
            .p_attr_val(margin-left, 13);
            .p_attr_val(margin-right, 80);
            // cursor: pointer;
          }

          .btn1 {
            .p_attr_val(width, 203);

          }
        }
      }
    }


  }
}

.topBar {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  // width: calc(100% - 17px);
  width: 100%;
  .p_attr_val(padding, 30);
  .p_attr_val(padding-top, 15);
  .p_attr_val(padding-right, 40);
  .p_attr_val(padding-bottom, 15);
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, .6);

  z-index: 9999;
  box-sizing: border-box;
  transition: ease all 0.3s;

  &Black {
    background-color: rgba(167, 167, 167, .6);
  }

  .topLogo {
    .p_attr_val(width, 123);

  }

  .topText {
    .p_attr_val(width, 52);

  }
}

.mobileMain {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;

  .infoIcon {
    .attr_val(width, 15);
    .position50();
    left: -20%;
  }

  .mobileTopBar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    background-color: rgba(254, 236, 217, 1);
    .attr_val(padding, 20);
    .attr_val(padding-left, 32);

    &Black {
      background-color: #4d3a40;

    }

    .logo {
      .attr_val(width, 207);
    }
  }

  .iframe {
    .attr_val(width, 750);
    .attr_val(height, 750*9/16);
    .attr_val(margin-top, 93);

  }

  .mbcontent {
    background: url('@/assets/images/mobile/mbbg.jpg') no-repeat;
    background-size: 100%;
    width: 100%;
    .attr_val(margin-top, -55);
    .attr_val(padding-top, 50);
    box-sizing: border-box;

    &Black {
      background: url('@/assets/images/mobileBlack/mbbg.jpg') no-repeat;

    }

    &tagother {
      height: calc(100% - 61.5vw);
    }

    .initroduceTagBox {
      .attr_val(width, 718);
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .initroduceTag:nth-child(2) {
        margin: 0 -5.5%;

      }

      .initroduceTag {
        .attr_val(width, 279);
        position: relative;

        .selectBg {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }

        .tagtitleText {
          .attr_val(width, 83);
          .position50();
          top: 43%;
        }
      }
    }

    .itemCountent {
      .attr_val(margin-top, 88);
      position: relative;

      .titleBox {
        .attr_val(width, 651);
        position: relative;
        .attr_val(margin-left, 68);

        .titleText {
          .attr_val(width, 182);
          position: absolute;
          top: 14%;
          left: 19%;
        }

        .titleText4 {
          .attr_val(width, 223);
          position: absolute;
          top: 14%;
          left: 19%;
        }
      }
    }

    .introduceiContent {
      .attr_val(margin-top, 60);

      .introduceText {
        .attr_val(width, 616);
        .attr_val(margin-left, 68);
      }

      .introduceNote {
        .attr_val(margin-top, 45);
        .attr_val(width, 290);
        .attr_val(margin-left, 68);

      }

      .line {
        .attr_val(width, 56);
        .attr_val(height, 3);
        background-color: #ff7e0a;
        position: absolute;
        right: 7.7%;
        top: 70%;
      }

      .iconBox {
        .attr_val(padding-left, 68);
        box-sizing: border-box;
        display: flex;
        width: 100%;
        align-items: center;
        .attr_val(margin-top, 64);

        .introduceicon {
          .attr_val(width, 149);
          .attr_val(margin-right, 7);
        }
      }
    }

    .miframe {
      position: absolute;
      left: -1000%;
      top: -1000%;
      .attr_val(width, 750);
      // height: calc(100vh - 88.5vw);
      // height: calc(100% - 84.5vw);
      height: calc(100% - 6.66666667vw - 10.5vw);
      .attr_val(min-height, 550);
    }

    .mobileComment,
    .mobileImagetext {
      position: relative;
      top: 0;
      left: 0;
    }

    .mbguestContent {
      .guestBox {
        position: relative;
        .attr_val(width, 701);
        margin: 0 auto;

        .guestTitleBox {
          .attr_val(width, 741);
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);

          .checktagBg {
            .attr_val(width, 271);
            position: absolute;
            top: 0%;
            left: 0.15%;
            transition: 0.3s ease all;
          }

          .checktagBg2 {
            left: 31.6%;

          }

          .checktagBg3 {
            left: 62.6%;

          }

          .guestTitle {
            .attr_val(width, 195);
            position: absolute;
            top: 34%;
            left: 5.6%;
          }

          .guestTitle2 {
            left: 37%;
          }

          .guestTitle3 {
            left: 68%;

          }
        }


        .mbguest {
          position: absolute;
          width: 85.7%;
          height: 73%;
          top: 17%;
          left: 7%;
          padding-top: 5%;
          padding-bottom: 5%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          overflow: hidden;
          overflow-y: auto;

          &Black {
            .meettingName {
              color: #fff !important;

            }
          }

          &::-webkit-scrollbar {
            .attr_val(width, 7);
          }

          &::-webkit-scrollbar-track {
            border-radius: 5px;
            background: #ffca9b;
          }

          /* 滚动条滑块 */
          &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-image: linear-gradient(#fa8e54, #f77234, #ffca9b);
            -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
          }

          ::-webkit-scrollbar-thumb:window-inactive {
            background: rgba(255, 0, 0, 0.4);
          }

          .guestItem {
            position: relative;
            .attr_val(width, 596);
            .attr_val(margin-bottom, -25);
            margin-right: -3%;
            transition: all ease 0.3s;
            color: #2e1d15;

            &Black {

              color: #fff;

              .meettingName,
              .guestName {
                color: #fff !important;

              }
            }

            .people {
              .attr_val(width, 180);
              position: absolute;
              left: 11%;
              bottom: 24%;
              transition: ease all 0.3s;
            }

            .guestInfo {
              .attr_val(width, 250);
              position: absolute;
              top: 45%;
              transform: translateY(-50%);
              left: 43%;

              .meettingName {
                color: #2e1d15;
                width: 100%;
                .attr_val(font-size, 18);
                font-weight: 800;
                letter-spacing: 2px;
              }

              .line {
                .attr_val(width, 36);
                .attr_val(height, 1);
                background-color: #ff7e0a;
                .attr_val(margin-bottom, 8);
                .attr_val(margin-top, 5);

              }

              .lineBlank {
                background-color: #fff;
              }

              .meetTime {
                .attr_val(font-size, 15);
                font-weight: 600;
                .attr_val(margin-bottom, 8);
                position: relative;
              }

              .meetTimeBlack {
                text-indent: 8.5%;
                .attr_val(margin-bottom, 0);

                .timeIcon {
                  left: 3.5%;
                }
              }

              .guestName {
                width: 100%;
                // overflow: hidden;
                // text-overflow: ellipsis;
                color: #2e1d15;
                .attr_val(font-size, 18);

                .identity {
                  .attr_val(font-size, 12);

                }

                .nameIcon {
                  .attr_val(width, 15);
                  // .position50();
                  // left: 3.5%;    transform: translateY(18%);
                  display: inline-block;
                  transform: translateY(18%);
                  margin-right: 0.8%;
                }
              }

              .desc {
                width: 100%;
                .attr_val(font-size, 15);
                .attr_val(margin-top, 15);
                font-weight: 500;
              }

              .typeTime {
                position: absolute;
                top: 13%;
                .attr_val(font-size, 15);
                right: 2%;
                font-weight: 600;
                // font-family: 'number';

              }

            }

            .guestInfoType1 {
              .attr_val(width, 450);
              position: absolute;
              top: 43.8%;
              left: 13%;
              transform: translateY(-50%);

            }

            .guestBgBorder {
              width: 100%;
              position: absolute;
              top: 0%;
              left: 0%;
              transform: scale(1);
              display: block;
            }

            .guestType1BgBorder {
              width: 100%;
              position: absolute;
              top: 0%;
              left: 0%;
              transform: scale(1);
              display: block;
            }
          }

          .selectGuest {
            transform: scale(1.05);
            transform-origin: 100% 50%;

            .lineBlank {
              background-color: #ff7e0a !important;
            }
          }
        }

        .guestMask {

          position: absolute;
          width: 89.5%;
          height: 33%;
          bottom: 9%;
          right: 5.78%;
          background-image: linear-gradient(to top, #fef1e2, rgba(255, 255, 255, 0));
          filter: blur(20px);
          pointer-events: none;

          &Black {
            background-image: linear-gradient(to top, #36323e, rgba(255, 255, 255, 0));
          }
        }
      }
    }

    .mbmethodContent {
      .mbmethod {
        position: relative;
        .attr_val(width, 586);
        .attr_val(margin-left, 39);

        .whiteBookBtn {
          position: absolute;
          .attr_val(width, 211);
          left: 13.6%;
          bottom: 16%;
        }

        .methodIcon {
          position: absolute;
          top: -41%;
          right: -14%;
          .attr_val(width, 540);
          pointer-events: none;
        }
      }

      .methodsBottom {

        .attr_val(width, 330);
        margin: 0 auto;
        .attr_val(margin-top, 39);
      }
    }

    .mbfriendContent {
      .friendBox {
        .attr_val(width, 470);
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .friend {
          .attr_val(width, 203);

        }
      }

      .attr_val(padding-bottom, 196);
    }
  }

}

// @media (max-width:375px) {
//   .mobileMain .mbcontent .mbguestContent .guestBox .mbguest .guestItem .guestInfo .desc {
//     transform: scale(0.7);
//     transform-origin: 0 50%;
//   }

//   .mobileMain .mbcontent .mbguestContent .guestBox .mbguest .guestItem .guestInfo .meetTime {
//     transform: scale(0.7);
//     transform-origin: 0 50%;
//     margin-bottom: -1vw;
//   }

//   .mobileMain .mbcontent .mbguestContent .guestBox .mbguest .guestItem .guestInfo .line {
//     margin: 0;
//   }

//   .mobileMain .mbcontent .mbguestContent .guestBox .mbguest .guestItem .guestInfo .guestName {
//     transform: scale(0.7);
//     width: 120%;
//     transform-origin: 0 50%;
//   }
// }
</style>
