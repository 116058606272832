import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { requiresAuth: true, },
    component: HomeView
  },
  {
    path: '/subscribe',
    name: 'subscribe',

    component: () => import('../views/SubscribePage.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router

// 跳转页面
const toPage = function (target) {
  if (target.indexOf('http') > -1) {
    location.href = target
    return
  }
  router.push(target)
}
export { toPage }